import React from 'react';
import stripeCreateSession from './../../services/stripeCreateSession'
import { loadStripe } from "@stripe/stripe-js";
import ReactLoading from 'react-loading';

export default function CardPlanMembresia({ titulo, descuento = null, precio, precioAnterior = null, precioMensual, tipo, currency, txtButton, nombrePlan, setPlanSelected, setModalLogin, login }) {
    
    const stripePromise = loadStripe(process.env.REACT_APP_KEY_STRIPE);
    const [ hideDescuento, setHideDescuento ] = React.useState(true);
    const [ hidePrecioAnterior, setHidePrecioAnterior ] = React.useState(true);
    let statusMembresia = localStorage.getItem('statusMembresia')
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if(descuento !== null){
            setHideDescuento(false);
        }
        if(precioAnterior !== null){
            setHidePrecioAnterior(false);
        }
    }, []);

    const handleInscribirseMembresia = ()=>{
        if(login){
            setLoading(true)
            setPlanSelected(nombrePlan)
            let data = {
                tipoMembresiaSeleccionada: nombrePlan,
                idUsuario: localStorage.getItem('idUsuario')
            }
            stripeCreateSession({values:data})
                .then(async respuesta=>{
                    console.log(respuesta);
                    var _respuesta = JSON.parse(respuesta);
                    var sessionId = _respuesta.id;
                    const stripe = await stripePromise
                    return stripe.redirectToCheckout({ sessionId: sessionId });
                })
        } else {
            setModalLogin(true)
        }
    }

    return(
        <div className={ tipo }>
            <h3 className="planes__titulo">
                { titulo }
                <span className={ `alertaDescuento__cantidad ${(hideDescuento) && 'hide' }`} role="alert">
                    { descuento*100 }% Dto.
                </span>
            </h3>
            <p className="planes__precio">
                <span className={ `planes__precioAnterior ${(hidePrecioAnterior) && 'hide'} ` }>
                    Antes: <span>${ precioAnterior } {currency} por año</span>
                </span>
                <br className={ (hidePrecioAnterior) && 'hide' } />
                ${ precioMensual }<span className="planes__tiempo"> <span className="fs-2">{currency}</span>/por mes</span>
            </p>
            <p className="planes__descripcion">
                El cobro final es de ${ precio } {currency} al año
            </p>
            <button 
                data-action="" 
                id="btnMembresia" 
                className={`d-flex justify-content-center align-items-center btnSuscribirMembresia btn btn-block planes__botonAccion ${ (tipo=='planes__secundario') && 'planes__botonAccion--secundario' }`}
                onClick={handleInscribirseMembresia}
                disabled={(statusMembresia === '1' || loading) ? true : false}
            >
                {
                    loading &&
                    <ReactLoading 
                        type="spin" 
                        color="#fff" 
                        height={'24px'} 
                        width={'24px'}
                        className="circularBarProgressIndicatorItem" 
                    />
                }
            
                {
                    !loading && txtButton
                }


            </button>
            <div className="planes__detalles">
                <small>
                    *Nuestras membresías son autorenovables
                </small>
            </div>
        </div>
    )
}